var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _c("feather-icon", {
        attrs: {
          title: "Remove from company",
          icon: "XIcon",
          svgClasses: "h-5 w-5 mr-4 hover:text-primary cursor-pointer",
        },
        on: { click: _vm.confirmDeleteRecord },
      }),
      _c(
        "vs-prompt",
        {
          attrs: {
            title: "Delete Customer",
            "accept-text": "Delete",
            active: _vm.showDeleteDialog,
          },
          on: {
            cancel: _vm.closeDeleteDialog,
            close: _vm.closeDeleteDialog,
            accept: _vm.deleteCustomer,
            "update:active": function ($event) {
              _vm.showDeleteDialog = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row mt-2 mb-4" }, [
            _c("div", { staticClass: "vx-col" }, [
              _c("span", [
                _vm._v(
                  "Are you sure you want to unlink customer " +
                    _vm._s(this.params.data.email) +
                    "?\n            "
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }