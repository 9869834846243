var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "2/3", pageTitle: _vm.pageTitle } },
    [
      _c(
        "div",
        { staticClass: "vx-col w-full mb-base" },
        [
          _c(
            "vx-card",
            [
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col w-full" }, [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("v-select", {
                        staticStyle: { width: "60%" },
                        attrs: {
                          placeholder: "Select users to add",
                          label: "label",
                          reduce: (x) => x.code,
                          options: _vm.usersForAssignment,
                        },
                        model: {
                          value: _vm.selectedUserId,
                          callback: function ($$v) {
                            _vm.selectedUserId = $$v
                          },
                          expression: "selectedUserId",
                        },
                      }),
                      _vm.userHasPermission("apps_customers_edit")
                        ? _c(
                            "vs-button",
                            {
                              staticClass: "ml-4 sm:mb-0 mb-2",
                              attrs: { disabled: !_vm.selectedUserId },
                              on: { click: _vm.addUser },
                            },
                            [_vm._v("\n              Add User\n            ")]
                          )
                        : _vm._e(),
                      _c(
                        "vs-button",
                        {
                          staticClass: "ml-4 sm:mb-0 mb-2",
                          attrs: { type: "border" },
                          on: { click: _vm.manageCompanies },
                        },
                        [
                          _vm._v(
                            "\n              Back To Companies List\n            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("ag-grid-vue", {
                ref: "agGridTable",
                staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
                attrs: {
                  components: _vm.cellRendererComponents,
                  columnDefs: _vm.columnDefs,
                  defaultColDef: _vm.defaultColDef,
                  rowData: _vm.linkedUsers,
                  gridOptions: _vm.gridOptions,
                  rowSelection: "single",
                  pagination: true,
                  paginationPageSize: _vm.paginationPageSize,
                  suppressPaginationPanel: true,
                  context: _vm.context,
                },
                on: {
                  "selection-changed": _vm.onSelectionChanged,
                  "grid-size-changed": _vm.onGridSizeChanged,
                },
              }),
              _c("vs-pagination", {
                attrs: { total: _vm.totalPages, max: 5 },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }